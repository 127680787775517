<template>
	<div>
		<page-header
			:loading="loading"
			:title="singlePartner ? `${singlePartner?.company_name}` : ''"
			pre-title="Vehicle Partner Overview"
			>
			<template slot="tabs" v-if="!errorLoading">
				<page-tabs :menu-tabs="menuTabs">
					<template v-slot="props">
						<feature-flag-enabled
							v-if="props.menu.featureFlag"
							:feature-flag="props.menu.featureFlag"
							>
							<router-link
								:to="{ name: props.menu.routeName }"
								class="nav-link"
								active-class="active"
								>
								<span>{{ props.menu.label }}</span>
							</router-link>
						</feature-flag-enabled>
						<router-link
							v-else
							:to="{ name: props.menu.routeName }"
							class="nav-link"
							active-class="active"
							>
							<span>{{ props.menu.label }}</span>
						</router-link>
					</template>
				</page-tabs>
			</template>

			<template slot="actions">
				<div class="btn-group mr-3" v-if="isTripsPage && userHasPermission('partners013')">
					<button
						type="button"
						class="btn btn-primary dropdown-toggle"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						>
						Link Driver
					</button>

					<div class="dropdown-menu dropdown-menu-right mt-3">
						<!-- <button class="dropdown-item" type="button">Update</button> -->
						<button
							@click="handleLinkingModal('driver')"
							class="dropdown-item"
							type="button"
							>
							Link Driver
						</button>
						<button
							@click="handleBatchLinkingModal('driver')"
							class="dropdown-item"
							type="button"
							>
							Batch Linking
						</button>
					</div>
				</div>

				<div class="btn-group mr-3">
					<button
						v-if="isVehiclesPage && userHasPermission('partners012')"
						type="button"
						class="btn btn-primary dropdown-toggle"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						>
						Link Vehicle
					</button>

					<div class="dropdown-menu dropdown-menu-right mt-3">
						<!-- <button class="dropdown-item" type="button">Update</button> -->
						<button
							@click="handleLinkingModal('vehicle')"
							class="dropdown-item"
							type="button"
							>
							Link Vehicle
						</button>
						<button
							@click="handleBatchLinkingModal('vehicle')"
							class="dropdown-item"
							type="button"
							>
							Batch Linking
						</button>
					</div>
				</div>
				<div class="btn-group">
					<button
						v-if="!isTripsPage"
						type="button"
						class="btn btn-primary dropdown-toggle"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						>
						Action
					</button>

					<div class="dropdown-menu dropdown-menu-right mt-3">
						<button
							v-if="userHasPermission('partners011')"
							class="dropdown-item"
							type="button"
							@click="suspendOrUnsuspendPartner()"
							>
							{{
								this.singlePartner?.status === 'active'
									? 'Suspend'
									: 'Unsuspend'
							}}
						</button>
						<button
							v-if="userHasPermission('partners004')"
							@click="showPasswordResetModal = true"
							class="dropdown-item"
							type="button"
							>
							Change password
						</button>
					</div>

					<change-user-password-modal
						:show="showPasswordResetModal"
						:user="{ id: singlePartner?.owner?.id }"
						></change-user-password-modal>
				</div>
			</template>
		</page-header>

		<batch-linking-modal
			:show="showBatchLinkingModal"
			:selectedItem="selected"
			@close="showBatchLinkingModal = false"
			@refreshVehiclesList="reloadVehicles"
			@refreshDriversList="reloadDrivers"
			:partnerId="partnerId"
			></batch-linking-modal>

		<breadcrum-component
			v-if="!loading"
			parentPath="Vehicle partners"
			childPath="Company Information"
			:partner="singlePartner"
			></breadcrum-component>

		<page-route-view
			:loading="loading"
			:error-loading="errorLoading"
			error-text="Failed to load partner information"
			backLink="/partners"
			></page-route-view>

		<assign-vehicle
			@close="showAddVehicleModal = false"
			@vehicle-assigned="onPartnerDataUpdated()"
			:partner-id="partnerId"
			:show="showAddVehicleModal"
			>
		</assign-vehicle>

		<b-modal no-close-on-backdrop no-stacking centered id="SingleLinkingModal">
			<template #modal-title>
				<div class="d-flex flex-column">
					<h3 class="mb-3">
						{{
							selectedLinkingItem === 'driver' ? 'Link driver' : 'Link vehicle'
						}}
					</h3>
				</div>
			</template>

			<div>
				<h3 class="font-weight-bold">
					Select
					{{ selectedLinkingItem === 'driver' ? 'driver' : 'vehicle' }}
					to link
				</h3>
				<p>
					You can select multiple
					{{ selectedLinkingItem === 'driver' ? 'drivers' : 'vehicles' }}
				</p>
			</div>

			<form>
				<div class="row">
					<div class="col-12">
						<div class="form-group mt-4">
							<label class="form-label text-muted" style="font-size: 15px"
								>Select
								{{
									selectedLinkingItem === 'driver' ? 'Drivers' : 'Vehicles'
								}}</label
								>
							<multiselect
								v-if="selectedLinkingItem === 'driver'"
								v-model="selectedDrivers"
								id="ajax"
								:custom-label="shuttlersDriver"
								track-by="id"
								placeholder="Select driver to link"
								open-direction="bottom"
								:options="drivers"
								:multiple="true"
								:searchable="true"
								:loading="isDriverLoading"
								:internal-search="false"
								:clear-on-select="false"
								:close-on-select="true"
								:preserve-search="true"
								:options-limit="300"
								:limit="10"
								:show-no-results="false"
								@search-change="asyncFindDrivers"
								>
								<template slot="selection" slot-scope="{ values, isOpen }">
									<span
										style="text-size: 10px"
										v-if="values.length && !isOpen"
										class="multiselect__single"
										>
										{{ values.length }} drivers selected
									</span>
								</template>
								<template slot="clear">
									<div
										class="multiselect__clear"
										v-if="selectedDrivers.length"
										@mousedown.prevent.stop="selectedDrivers = null"
										></div>
								</template>

								<span slot="noResult"
									>Oops! No driver found. Consider changing the search
									query.</span
									>
							</multiselect>

							<multiselect
								v-if="selectedLinkingItem === 'vehicle'"
								v-model="selectedVehicles"
								id="ajax"
								:custom-label="shuttlersVehicle"
								track-by="id"
								placeholder="Select vehicles to link"
								open-direction="bottom"
								:options="vehicles"
								:multiple="true"
								:searchable="true"
								:loading="isVehicleLoading"
								:internal-search="false"
								:clear-on-select="false"
								:close-on-select="true"
								:preserve-search="true"
								:options-limit="300"
								:limit="10"
								:show-no-results="false"
								@search-change="asyncFindVehicles"
								>
								<template slot="selection" slot-scope="{ values, isOpen }">
									<span
										style="text-size: 10px"
										v-if="values.length && !isOpen"
										class="multiselect__single"
										>
										{{ values.length }} vehicles selected
									</span>
								</template>
								<template slot="clear">
									<div
										class="multiselect__clear"
										v-if="selectedVehicles.length"
										@mousedown.prevent.stop="selectedVehicles = null"
										></div>
								</template>

								<span slot="noResult"
									>Oops! No vehicle found. Consider changing the search
									query.</span
									>
							</multiselect>
						</div>
					</div>
				</div>
			</form>

			<b-row cols="4" v-if="selectedLinkingItem === 'vehicle'" class="grid">
				<b-col
					style="
            background-color: #f4f5f4;
            font-size: 10px;
            font-weight: lighter;
          "
					v-for="(eachVehicle, index) in selectedVehicles"
					:key="index"
					class="parentClass rounded border m-2 py-2 text-center text-sm d-flex flex-row"
					>
					<!-- <button class="childClass" @click.self="removeVehicle(eachVehicle)">
            <img src="@/assets/img/delete.svg" />
          </button> -->
					{{ eachVehicle.brand }} {{ eachVehicle.name }} - ({{
						eachVehicle.registration_number
					}})
				</b-col>
			</b-row>

			<!-- <div class="d-flex flex-column mt-3">
        <div v-if="selectedVehicles.length" class="mb-3 d-flex flex-column">
          <div class="d-flex align-content-center mt-1">
            <div><input type="checkbox" v-model="generateRevenue" /></div>
            <p class="text-muted pl-2">
              Generate revenue for selected vehicles
            </p>
          </div>
          <div v-if="showDatePicker">
            <small class="mb-1">Select revenue generation date range:</small>
            <v-datepicker
              style="width: 100%"
              class="float-left"
              v-model="filter.range"
              placeholder="Filter by date"
              range
            ></v-datepicker>
          </div>
        </div>
      </div> -->

			<b-row cols="4" v-if="selectedLinkingItem === 'driver'" class="grid">
				<b-col
					style="
              background-color: #f4f5f4;
              font-size: 10px;
              font-weight: lighter;
              d-flex justify-content-center align-items-center
            "
					class="parentClass rounded border m-1 py-2 text-center text-sm d-flex flex-row"
					v-for="(eachDriver, index) in selectedDrivers"
					:key="index"
					>
					<!-- <button class="childClass" @click.self="removeDriver(eachDriver)">
            <img src="@/assets/img/delete.svg" />
          </button> -->
					{{ eachDriver.fname }} {{ eachDriver.lname }}
				</b-col>
			</b-row>

			<template #modal-footer>
				<button
					type="button"
					class="btn btn-secondary mr-3"
					data-dismiss="modal"
					ref="closeModalButton"
					@click="$bvModal.hide('SingleLinkingModal')"
					>
					Close
				</button>
				<button
					v-if="selectedLinkingItem === 'vehicle'"
					type="button"
					:class="[
						selectedVehicles.length ? 'btn-primary' : 'btn-secondary',
						'btn',
					]"
					:disabled="!selectedVehicles.length"
					@click.prevent="handleLinkingConfirmation"
					>
					Proceed
				</button>

				<button
					v-if="selectedLinkingItem === 'driver'"
					type="button"
					:class="[
						selectedDrivers.length ? 'btn-primary' : 'btn-secondary',
						'btn',
					]"
					:disabled="!selectedDrivers.length"
					@click.prevent="handleLinkingConfirmation"
					>
					Proceed
				</button>
			</template>
		</b-modal>

		<b-modal no-close-on-esc no-close-on-backdrop id="linkingConfirmationModal">
			<template #modal-title>
				<div class="d-flex flex-column">
					<h3 class="mb-3">Are you absolutely sure?</h3>
				</div>
			</template>
			<div
				class="alertDisplay rounded p-3 d-flex align-items-center justify-content-center"
				role="alert"
				>
				<div>
					<img
						src="@/assets/img/caution.svg"
						class="h-3 w-3 pr-2"
						alt="cauton"
						/>
				</div>
				<div>
					<span class="alertText"
						>It’s important you read the message below!</span
						>
				</div>
			</div>
			<p class="font-weight-light py-3">
				If this
				{{ selectedLinkingItem === 'driver' ? 'driver' : 'vehicle' }} has
				already been assigned to another vehicle partner, assigning the
				vehicle/driver to this new partner would automatically unassign the
				{{ selectedLinkingItem === 'driver' ? 'driver' : 'vehicle' }} from the
				previous vehicle partner.
			</p>
			<form>
				<div class="form-group">
					<label for="exampleInputOption" class="text-secondary"
						>Please type <span class="font-weight-bold text-dark">YES</span> to
						confirm this action.</label
						>
					<input
						v-model="confirmation"
						type="text"
						class="form-control"
						id="exampleInputOption"
						aria-describedby="option"
						/>
				</div>
			</form>
			<template #modal-footer>
				<b-button @click="hide">Cancel</b-button>
				<b-button
					v-if="selectedLinkingItem === 'driver'"
					type="submit"
					@click="proceedingLinking('driver')"
					:class="[
						confirmation.includes('YES') ? 'bg-primary' : 'bg-secondary',
					]"
					:disabled="!confirmation.includes('YES')"
					>
					{{
						driverModalLoadingState ? 'Processing..' : 'Link driver'
					}}</b-button
					>

				<b-button
					v-if="selectedLinkingItem === 'vehicle'"
					type="submit"
					@click="proceedingLinking('vehicle')"
					:class="[
						confirmation.includes('YES') ? 'bg-primary' : 'bg-secondary',
					]"
					:disabled="!confirmation.includes('YES')"
					>
					{{
						vehicleModalLoadingState ? 'Processing..' : 'Assign vehicle'
					}}</b-button
					>
			</template>
		</b-modal>
	</div>
</template>


<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import FeatureFlagEnabled from '@/components/layout/FeatureFlagEnabled'
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import PageRouteView from '@/components/layout/PageRouteView'
import AssignVehicle from '@/views/Partners/components/AssignVehicle'
import BreadcrumComponent from '@/views/Partners/components/BreadcrumComponent'
import BatchLinkingModal from '@/views/Partners/components/BatchLinkingModal'
import Swal from 'sweetalert2'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import {
  filterDriverBy,
  filterVehicleBy
} from '@/utils/helpers'
import ChangeUserPasswordModal from '../../../components/modals/ChangeUserPasswordModal.vue'

// TODO: Review implementation with use of vuex
export default {
  name: 'PartnerProfileIndex',
  components: {
    AssignVehicle,
    PageRouteView,
    FeatureFlagEnabled,
    PageTabs,
    PageHeader,
    BreadcrumComponent,
    ChangeUserPasswordModal,
    Multiselect,
    BatchLinkingModal
  },
  props: {
    partnerId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      loading: false,
      errorLoading: false,
      partnerData: null,
      singlePartner: null,
      alreadyAssignedVehicle: false,
      processing: false,
      alreadyAssignedDriver: false,
      driverModalLoadingState: false,
      vehicleModalLoadingState: false,
      isDriverLoading: false,
      isVehicleLoading: false,
      confirmation: '',
      drivers: [],
      vehicles: [],
      selectedDrivers: [],
      selectedVehicles: [],
      selectedLinkingItem: '',
      showDriverLabel: false,
      showVehicleLabel: false,
      showPasswordResetModal: false,
      showBatchLinkingModal: false,
      selected: '',
      // form: {
      //   title: '',
      //   description: '',
      // },
      IndividualMenuTabs: [
        {
          routeName: 'PartnerDetails',
          label: 'Partner Information'
        },
        // {
        //   routeName: 'PartnerDocuments',
        //   label: 'Documents',
        //   featureFlag: FEATURE_FLAGS.VEHICLE_PARTNERS
        // },
        {
          routeName: 'PartnerVehicles',
          label: 'Vehicles'
        },
        {
          routeName: 'PartnerDrivers',
          label: 'Drivers'
        },
        {
          routeName: 'PartnerTripEarnings',
          label: 'Completed Trips'
          // featureFlag: FEATURE_FLAGS.REVENUE_AND_COST_AUTOMATION
        }
        // {
        //   routeName: 'PartnerCostConfiguration',
        //   label: 'Cost Configuration',
        //   featureFlag: FEATURE_FLAGS.REVENUE_AND_COST_AUTOMATION
        // },
      ],
      BussinessMenuTabs: [
        {
          routeName: 'PartnerDetails',
          label: 'Partner Information'
        },
        {
          routeName: 'CompanyInformation',
          label: 'Company Information'
        },
        // {
        //   routeName: 'PartnerDocuments',
        //   label: 'Documents',
        //   featureFlag: FEATURE_FLAGS.VEHICLE_PARTNERS
        // },

        {
          routeName: 'PartnerVehicles',
          label: 'Vehicles'
        },
        {
          routeName: 'PartnerDrivers',
          label: 'Drivers'
        },
        {
          routeName: 'PartnerTripEarnings',
          label: 'Trips'
          // featureFlag: FEATURE_FLAGS.REVENUE_AND_COST_AUTOMATION
        }
        // {
        //   routeName: 'PartnerCostConfiguration',
        //   label: 'Cost Configuration',
        //   featureFlag: FEATURE_FLAGS.REVENUE_AND_COST_AUTOMATION
        // },
      ],
      partnerChangeListenerFunction: () => {},

      // action parameters
      showAddVehicleModal: false,
      showLinkDriverModal: false,
      currentRouteState: this.$route
    }
  },
  watch: {
    $route (to) {
      this.currentRouteState = to
    },
    generateRevenue (val) {
      if (val) {
        this.showDatePicker = true
      } else {
        this.showDatePicker = false
        this.filter.range = []
      }
    }
  },
  provide () {
    return {
      partnerPageData: () => {
        return {
          partnerData: this.singlePartner,
          registerChangeListener: (listenerFunction) => {
            this.partnerChangeListenerFunction = listenerFunction
          }
        }
      }
    }
  },
  created () {
    this.fetchSinglePartner()
    this.fetchDrivers()
    this.fetchVehicles()
  },
  methods: {
    handleBatchLinkingModal (value) {
      if (value === 'driver') {
        this.selected = 'driver'
      }

      if (value === 'vehicle') {
        this.selected = 'vehicle'
      }
      this.showBatchLinkingModal = true
    },
    removeVehicle (vehicle) {
      return (this.selectedVehicles = this.selectedVehicles.filter((v) => {
        return v.id === vehicle.id
      }))
    },
    reloadVehicles () {
      this.fetchSinglePartner()
    },

    reloadDrivers () {
      this.fetchSinglePartner()
    },
    removeDriver (driver) {
      return (this.selectedDrivers = this.selectedDrivers.filter((d) => {
        return d.id === driver.id
      }))
    },
    shuttlersDriver ({ fname, lname }) {
      return `${fname} ${lname}`
    },
    shuttlersVehicle ({ brand, name, registration_number }) {
      return `${brand} ${name} - (${registration_number})`
    },
    asyncFindDrivers (query) {
      this.isDriverLoading = true
      this.axios
        .post('/v1/drivers/search', { name: query })
        .then((response) => {
          this.drivers = response.data
          this.isDriverLoading = false
        })
        .catch((e) => {
          this.isDriverLoading = false
          this.drivers = []
        })
    },

    asyncFindVehicles (query) {
      this.isVehicleLoading = true
      this.axios
        .post('/v1/vehicles/search', { search_term: query })
        .then((response) => {
          this.vehicles = response.data
          this.isVehicleLoading = false
        })
        .catch((e) => {
          this.isVehicleLoading = false
          this.vehicles = []
        })
    },
    handleSelected () {
      // this.selectedVehicles = null
      this.vehicles = null
    },
    handleLinkingModal (item) {
      this.selectedLinkingItem = item
      this.$bvModal.show('SingleLinkingModal')
    },
    hide () {
      this.$bvModal.hide('linkingConfirmationModal')
      this.$bvModal.show('linkDriverModal')
      this.confirmation = ''
    },

    resetModalState () {
      return (this.confirmation = '')
    },
    handleLinkingConfirmation () {
      this.$bvModal.show('linkingConfirmationModal')
    },

    async proceedingLinking (item) {
      if (item === 'driver') {
        this.driverModalLoadingState = true
        const driversIdPayload = this.selectedDrivers.map((eachDriver) => ({
          partner_id: Number(this.partnerId),
          driver_id: eachDriver.id
        }))

        await this.axios
          .post('/v1/partners/batch-assign-drivers', driversIdPayload)
          .then(() => {
            this.$bvModal.hide('linkingConfirmationModal')
            this.$bvModal.hide('linkDriverModal')
            this.resetModalState()
            this.driverModalLoadingState = false
            this.fetchSinglePartner()
            this.$toastr.s('Drivers sucessfully linked', 'Success')
            this.selectedDrivers = []
          })
          .catch((error) => {
            this.driverModalLoadingState = false
            this.$toastr.e(error.response.data.message, 'Error')
          })
          .finally(() => {
            this.driverModalLoadingState = false
          })
      }

      if (item === 'vehicle') {
        this.vehicleModalLoadingState = true
        const vehiclesIdPayload = this.selectedVehicles.map((eachVehicle) => ({
          partner_id: Number(this.partnerId),
          vehicle_id: eachVehicle.id
          // generate_revenue: this.generateRevenue,
          // generate_revenue_start_date: this.dateRange[0],
          // generate_revenue_end_date: this.dateRange[1],
        }))

        await this.axios
          .post('/v1/partners/batch-assign-vehicles', vehiclesIdPayload)
          .then(() => {
            this.$bvModal.hide('linkingConfirmationModal')
            this.$bvModal.hide('linkDriverModal')
            this.resetModalState()
            this.vehicleModalLoadingState = false
            this.fetchSinglePartner()
            this.$toastr.s('Vehicles successfully linked', 'Success')
            this.selectedVehicles = []
          })
          .catch(() => {
            this.vehicleModalLoadingState = false
            this.$toastr.e(
              'Something went wrong while linking driver!',
              'Error'
            )
          })
          .finally(() => {
            this.vehicleModalLoadingState = false
          })
      }
    },

    updateSelectedDriver (value) {
      if (value) {
        this.showDriverLabel = true
      }
      if (value) {
        const vehicle = this.vehicles.find((o) => o.id == value.vehicle_id)
        if (vehicle) {
          this.alreadyAssignedDriver = true
          this.linkDriverForm.vehicle = vehicle
        } else {
          this.alreadyAssignedDriver = false

          // this.linkDriverForm.vehicle = null
        }
      }
    },
    updateSelectedVehicle (value) {
      if (value) {
        this.showVehicleLabel = true
      }
      if (value) {
        const driver = this.drivers.find((o) => o.id == value.partner_id)
        if (driver) {
          this.alreadyAssignedVehicle = true
          this.linkDriverForm.driver = driver
        } else {
          this.alreadyAssignedVehicle = false

          // this.linkDriverForm.driver = null
        }
      }
    },
    fetchDrivers () {
      this.isDriverLoading = true
      this.axios
        .get('/v1/drivers')
        .then((res) => {
          this.drivers = res.data
          this.isDriverLoading = false
        })
        .catch(() => {
          this.isDriverLoading = false
          this.drivers = []
        })
    },
    driverFilter (option, label, search) {
      return filterDriverBy(option, label, search)
    },
    fetchVehicles () {
      this.isVehicleLoading = true
      this.axios
        .get('/v1/vehicles')
        .then((res) => {
          this.vehicles = res.data.data
          this.isVehicleLoading = false
        })
        .catch(() => {
          this.isVehicleLoading = false
          this.vehicles = []
        })
    },
    vehicleFilter (option, label, search) {
      return filterVehicleBy(option, label, search)
    },
    async suspendOrUnsuspendPartner () {
      const isSuspendAction = this.isPartnerActive
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${
          this.isPartnerActive ? 'suspend' : 'unsuspend'
        } this Partner?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/partners/${this.partnerId}`, {
              company_name: this.singlePartner.company_name,
              status: this.isPartnerActive ? 'suspended' : 'active'
            })
            .then((res) => {
              this.fetchSinglePartner()
              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `Partner has been ${
              isSuspendAction ? 'suspended' : 'unsuspended'
            } successfully`,
            showCloseButton: true
          })
        }
      })
    },

    onPartnerDataUpdated () {
      try {
        this.partnerChangeListenerFunction.call(this)
        this.fetchSinglePartner(false)
      } catch (e) {
        (e)
      }
    },
    async fetchSinglePartner (showLoader = true) {
      try {
        if (showLoader) {
          this.loading = true
        }
        const response = await this.axios.get(`/v1/partners/${this.partnerId}`)
        this.singlePartner = response.data
        this.partnerData = response.data
      } catch (e) {
        
      } finally {
        this.loading = false
      }
    },
    openAddVehicleModal () {
      this.showAddVehicleModal = true
    },
    openLinkDriverModal () {
      this.showLinkDriverModal = true
    }
  },
  computed: {
    isPartnerVehiclesContext () {
      return this.currentRouteState?.name === 'PartnerVehicles'
    },
    isTripsPage () {
      return !!(this.$route.path.split('/')[4] === 'trip-earnings' ||
        this.$route.path.split('/')[4] === 'drivers')
    },
    isVehiclesPage () {
      return this.$route.path.split('/')[4] == 'vehicles'
    },
    isPartnerActive () {
      if (this.singlePartner?.status === 'active') {
        return true
      }
      return false
    },
    menuTabs () {
      return this.singlePartner?.mode === 'business'
        ? this.BussinessMenuTabs
        : this.IndividualMenuTabs
    },
    availableVehicles () {
      return this.vehicles.length ? this.vehicles : this.vehicleList
    },
    formValid () {
      return !!(this.linkDriverForm?.driver !== null &&
        this.linkDriverForm?.vehicle !== null)
    },

    dateRange () {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid () {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    },
    vehicleLinkingFormValid () {
      return !!this.selectedVehicles.length
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/nav-tabs';

.alertDisplay {
  background-color: #ebebff;
}

.parentClass {
  display: relative;
}

.childClass {
  display: absolute;
  top: 0px;
  left: 0px;
  border: none;
  outline: none;
}
</style>
